import styled from "styled-components";

export const Spacer = styled.div`
  width: 12px;
`;

export const TrackNo = styled.div`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  width: 36px;
  min-width: 36px;
`;

export const ItemNumber = styled.div`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  width: 36px;
`;

export const Exclusive = styled.span`
  right: 0;
  top: 0;
  height: 14px;
  padding: 0 2px;
  font-size: 7px;
  background: #01ff95;
  width: 100%;
  color: #000;
  position: absolute;
`;

export const ReleaseName = styled.span`
  ont-family: "Aeonik";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.005em;

  color: ${(props) => props.theme.colors.neutrals.primary.white};
`;

export const Genres = styled.div`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ArtworkAndControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  a.artwork {
    position: relative;
  }
`;

export const Wrapper = styled.div`
  .fade {
    opacity: 0.25;
    transition: opacity 0.2s ease-in-out;
  }

  .row {
    justify-content: flex-start;

    &:hover .fade {
      opacity: 1;
    }

    &.current {
      background: ${(props) =>
		props.theme.colors.neutrals.secondary.gray.active};
    }
  }

  .cell {
    padding-top: 0;
    padding-bottom: 0;

    a:hover {
      text-decoration: underline;
    }
  }

  .controls {
    padding-left: 0;
    width: 120px;
    min-width: 120px;
  }

  .title {
    width: 100%;
    overflow: hidden;

    .container {
      overflow: hidden;
      > div,
      > a > span {
        overflow: hidden;
      }
    }
  }
  .label {
    width: 200px;
    min-width: 200px;
  }
  .date {
    width: 120px;
    min-width: 120px;
  }

  .bpm {
    width: 140px;
    min-width: 140px;
  }

  .cart-actions {
    width: 220px;
    min-width: 220px;
    display: flex;
    justify-content: flex-end;
  }

  .card {
    width: 100px;
    min-width: 100px;
  }

  &.numbers {
    .controls {
      width: 165px;
      min-width: 165px;
    }
  }
`;
